import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export function createOrbitControls(
  camera: THREE.PerspectiveCamera,
  canvas: HTMLCanvasElement
) {
  const controls = new OrbitControls(camera, canvas);
  controls.enableDamping = true;
  controls.enableZoom = false;
  controls.enablePan = false;
  // controls.minPolarAngle = 1.4;
  // controls.maxPolarAngle = 1.4;
  controls.dampingFactor = 0.04;
  controls.rotateSpeed = 0.5;

  return controls;
}
