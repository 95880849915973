import * as THREE from "three";

export function createCamera(sizes: { width: number; height: number }) {
  const camera = new THREE.PerspectiveCamera(
    30,
    sizes.width / sizes.height,
    0.1,
    1500
  );
  return camera;
}
