import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";

interface HoverIntentProps {
  href?: string;
  icon?: "audio" | "audio--rounded" | "pulse" | "pulse--rounded";
  image?: string;
  label?: string;
  subtitle?: string;
  onClick?: () => void;
}


function removeClass(elem, className) {
  if (elem) {
    let classes = (className || "").split(" ");
    for (var i = classes.length - 1; i >= 0; i--) {
      if (elem.classList) {
        elem.classList.remove(classes[i]);
      } else if (hasClass(elem, classes[i])) {
        var reg = new RegExp("(\\s|^)" + classes[i] + "(\\s|$)");
        elem.className = elem.className.replace(reg, "");
      }
    }

    return elem;
  }
  return;
}

function hasClass(elem, className) {
  if (elem.classList) return elem.classList.contains(className);
  else
    return !!elem.className.match(
      new RegExp("(\\s|^)" + className + "(\\s|$)")
    );
}


function addClass(elem, className) {
  let classes = (className || "").split(" ");

  for (var i = classes.length - 1; i >= 0; i--) {
    if (elem.classList) elem.classList.add(classes[i]);
    else if (!hasClass(elem, classes[i])) elem.className += " " + classes[i];
  }

  return elem;
}

function clearAll() {
  const intents = document.querySelectorAll(".hover-intent");
  intents.forEach((intent) =>
    removeClass(intent.parentElement, "show-hover-intent")
  );
}

export function setupHoverEvents(intent) {
  let timeout;

  function mouseEnter() {
    clearTimeout(timeout);
    clearAll();
    addClass(intent.parentElement, "show-hover-intent");
  }

  function mouseLeave() {
    timeout = setTimeout(
      () => removeClass(intent.parentElement, "show-hover-intent"),
      200
    );
  }

  function pointerEvent() {
    if (hasClass(intent.parentElement, "show-hover-intent")) {
      mouseLeave();
    } else {
      mouseEnter();
    }
  }

  if (matchMedia("(pointer:fine)").matches) {
    intent.addEventListener("mouseenter", mouseEnter);
    intent.addEventListener("mouseleave", mouseLeave);
  } else {
    intent.addEventListener("pointerdown", pointerEvent);
  }
}

/**
 * Primary UI component for user interaction
 */
const HoverIntent = ({
  image,
  label,
  subtitle,
}: HoverIntentProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current!) {
      setupHoverEvents(divRef.current);
    }
  }, []);

  return (
    <div className="hover-intent cursor-hover relative shadow-lg" data-label={label} ref={divRef}>
      <div className="hover-intent__icon border border-conservationInternational-primary bg-black/80 duration-300 flex h-12 items-center justify-center relative rounded-full transition-all w-12">
        <div className="bg-conservationInternational-primary/50 h-7 rounded-full transition-all w-7">
          <span className="absolute bg-conservationInternational-primary h-2 inset-x-0 inset-y-0 m-auto rounded-full transition-all w-2 z-10"></span>
        </div>
      </div>
      <div className="hover-intent__target">
        <div className="flex font-bold items-center text-lg text-white uppercase">
          <span data-intent-title>{label || ""}</span>
          <svg className="ml-2" width="15" height="10" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.49978 0.499986L11.375 4.50001L7.49978 8.5" stroke="white"></path><path d="M11.5 4.49999L1.20583 4.49999L0.5 4.5" stroke="white"></path></svg>
        </div>
        <div className="font-light text-sm text-white mb-4 uppercase" data-intent-subtitle>
          {subtitle || ""}
        </div>
        <div className="hover-intent__image shadow-lg">
          <img
            data-intent-image
            alt="Conservation International"
            src="/conservation-international__thumbnail.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default HoverIntent;
