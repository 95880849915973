import React, { useEffect, useRef } from "react";

import { Region } from "../../interfaces";
import Globe from "../Globe";
import GlobeController, { GlobeSettings } from "../Globe/model/controller";

function hasClass(elem, className) {
  if (elem.classList) return elem.classList.contains(className);
  else
    return !!elem.className.match(
      new RegExp("(\\s|^)" + className + "(\\s|$)")
    );
}

const elementExists = (obj) => {
  try {
    // Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    // Browsers not supporting W3 DOM2 don't have HTMLElement and
    // an exception is thrown and we end up here. Testing some
    // properties that all elements have (works on IE7)
    return (
      typeof obj === "object" &&
      obj.nodeType === 1 &&
      typeof obj.style === "object" &&
      typeof obj.ownerDocument === "object"
    );
  }
};

function addClass(elem, className) {
  let classes = (className || "").split(" ");

  for (var i = classes.length - 1; i >= 0; i--) {
    if (elem.classList) elem.classList.add(classes[i]);
    else if (!hasClass(elem, classes[i])) elem.className += " " + classes[i];
  }

  return elem;
}

function removeClass(elem, className) {
  if (elem) {
    let classes = (className || "").split(" ");
    for (var i = classes.length - 1; i >= 0; i--) {
      if (elem.classList) {
        elem.classList.remove(classes[i]);
      } else if (hasClass(elem, classes[i])) {
        var reg = new RegExp("(\\s|^)" + classes[i] + "(\\s|$)");
        elem.className = elem.className.replace(reg, "");
      }
    }

    return elem;
  }
  return;
}

function toggleClass(elem, className) {
  if (hasClass(elem, className)) {
    removeClass(elem, className);
  } else {
    addClass(elem, className);
  }
}

interface GlobeViewProps {
  hotspots?: boolean;
  globeSettings?: GlobeSettings;
  regions?: Region[];
  triggerTransition?: (config: any) => void;
}

const windowWidth = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];

  return w.innerWidth || e.clientWidth || g.clientWidth;
};

const BREAKPOINT = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const GlobeView = ({
  globeSettings = {
    prePosition: "prelanding",
    initialPosition: "welcome",
    enableControls: true,
    rotateClouds: true,
  },
  hotspots,
  regions,
}: GlobeViewProps) => {
  const [controls, setControls] = React.useState<GlobeController | null>(null);

  const blockRef = useRef<HTMLDivElement>(null);

  if (controls) {
    document.addEventListener("conservation::globeview", (e) => handleEvent(e));

    controls.setShowHotspots(hotspots);
  }

  function handleEvent(e) {
    if (e.detail) {
      if (blockRef.current!) {
        if (e.detail.touchEnabled) {
          addClass(blockRef.current, "allow-interaction");
        } else {
          removeClass(blockRef.current, "allow-interaction");
        }

        var el = blockRef.current;
        for (let i = el.classList.length - 1; i >= 0; i--) {
          const className = el.classList[i];
          if (className.startsWith("current-state--")) {
            removeClass(el, className);
          }
        }

        addClass(blockRef.current, `current-state--${e.detail.newState}`);
      }

      controls.setShowHotspots(e.detail.showHotspots);
      controls.transform(e.detail.newState);
    }
  }

  let hasSetupHoverIntents = false;
  let timeout;
  function setupHoverIntents() {
    const hoverIntents = document.querySelectorAll<HTMLElement>(
      ".hover-intent"
    );

    if (hoverIntents.length && !hasSetupHoverIntents) {
      clearTimeout(timeout);

      hoverIntents.forEach((intent) => {
        const active = intent.dataset.label;

        if (active) {
          const item = regions?.find((data) => data.hotspot === active);

          if (item) {
            const href = intent.querySelector<HTMLElement>(
              "[data-intent-href]"
            );
            const title = intent.querySelector<HTMLElement>(
              "[data-intent-title]"
            );
            const subtitle = intent.querySelector<HTMLElement>(
              "[data-intent-subtitle]"
            );
            const image = intent.querySelector<HTMLElement>(
              "[data-intent-image]"
            );

            if (elementExists(title) && item.name) {
              title.innerHTML = item.name;
            }

            if (elementExists(subtitle)) {
              subtitle.innerHTML = item.artist
                ? `FT. ${item.artist}`
                : item.region || "";
            }

            if (elementExists(image) && item.image) {
              image.setAttribute('src', item.image);
            }
          } else {
            addClass(intent, "hidden");
          }
        }
      });
    } else {
      timeout = setTimeout(setupHoverIntents, 400);
    }
  }

  useEffect(() => {
    if (blockRef.current!) {
      setupHoverIntents();
    }
  });

  function handleLoad(controller: GlobeController) {
    setControls(controller);
  }

  function handleHoverGlobe(intersections: string[]) {
  
  }

  function handleClickGlobe(e, intersections: string[]) {
    
  }

  return (
    <div ref={blockRef}>
      <Globe
        onLoad={handleLoad}
        events={{
          mousemove: handleHoverGlobe,
          pointerdown: handleClickGlobe,
        }}
        {...globeSettings}
      />
    </div>
  );
};

export default GlobeView;
