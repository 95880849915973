import React from "react";
import GlobeController, { GlobeSettings } from "./model/controller";
import { GlobeEvents, load } from "./model/experience";

export interface Props extends GlobeSettings {
  onLoad: (controller: GlobeController) => void;
  events?: GlobeEvents;
}

function Globe({ onLoad, events, ...props }: Props) {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (typeof window === "undefined" || !ref.current) {
      return;
    }

    return load(ref.current, onLoad, props, events);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref} className="globe h-full w-full">
      <canvas className="webgl"></canvas>
    </div>
  );
}

export default Globe;
