import React, { useEffect, useState } from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"

import "../../styles/conservation-international.css"
import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle"
import GlobeView from "../../components/Globe/GlobeView"




const ArsenalPage = (props: PageProps) => {
  const [globeHideButton, handleGlobeHideButton] = useState('Toggle Hide');
  gsap.config();
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);

  ScrollTrigger.defaults({ ignoreMobileResize: true });

  const toggleHotspots = (activeClassname) => {
    const toggles = document.querySelectorAll('.hotspot-toggle');
    toggles.forEach(toggle => {
      if(!toggle.classList.contains(activeClassname)) {
        toggle.classList.add('opacity-0');
        toggle.classList.remove('opacity-100');
      } else {
        toggle.classList.add('opacity-100');
        toggle.classList.remove('opacity-0');
      }
    });
  }

  const toggleGlobe = (showHotspots, newState) => {
    document.dispatchEvent(
      new CustomEvent("conservation::globeview", {
        bubbles: true,
        detail: {
          touchEnabled: true,
          showHotspots,
          newState,
        },
      })
    );
  }
  

  const clamp = (num, min, max) =>  num <= min ? min : num >= max ? max : num;
  const map = (x, a, b, c, d) => clamp(((x - a) * (d - c)) / (b - a) + c, Math.min(c, d), Math.max(c, d));

  useEffect(() => {
    const anchor = document.querySelector(".anchor-menu");
    const target = document.querySelector(".conservation-international-namespace .split-text");
    let chars;
    let SplitClient = new SplitText(target, { type: "words,chars" });
    chars = SplitClient.chars;
    const ease = 0.6;
    let followY = 0;

    function splitTextScroll() {
      const scrollY = window.scrollY;
      followY += (scrollY - followY) * ease;
      var dy = (scrollY - followY);
      dy = Math.min(Math.max(dy, -15), 15);

      const translateY = map(dy, 150, -150, -50, 50);
      
      const charsTotal = chars.length;
      chars.forEach((char, i) => {
        const factor =
          i < Math.ceil(charsTotal / 2)
            ? i
            : Math.ceil(charsTotal / 2) -
              Math.abs(Math.floor(charsTotal / 2) - i) -
              1;
        gsap.fromTo(char, {transform: `translate3d(0,${factor * translateY}px,0)`}, {transform: 'translate3d(0, 0, 0)', duration: 1, ease: "elastic"});
       
      })
    }

    gsap.from(anchor, {
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: anchor,
        toggleClass: 'enable'
      }
    });

    const anchorIcon = anchor.querySelector('.icon--bullet');
    const anchorBlock = document.querySelector('.anchor-block');
    const anchorLinks = anchor.querySelectorAll('button');

    function resetAnchorLinks() {
      anchorLinks.forEach((anchorLink) => {
        anchorLink.closest("li")?.classList.remove('is-active');
      })
    }

    anchorLinks.forEach((anchorLink) => {
      anchorLink.addEventListener('click', () => {
        const target = anchorLink.dataset.target;
        const targetElement = anchorBlock?.querySelector(target)
        resetAnchorLinks();
        anchorBlock.scrollTo({ top: (targetElement.offsetTop - anchorBlock.offsetTop) + 1, behavior: "smooth"})
      })
    })

    const titles = anchorBlock?.querySelectorAll('.anchor-block__title');
    let currActive = titles[0].getAttribute('id');
    function anchorBlockScroll() {
      const scrollTop = anchorBlock?.scrollTop
      let activeItems = []
      
      titles?.forEach((title) => {
        if (scrollTop > title.offsetTop - anchorBlock.offsetTop) {
          const newActive = title.getAttribute('id')
          activeItems.push(newActive)
        }
      })

      const activeItem = activeItems.slice(-1);
      if (activeItem.toString() !== currActive.toString()) { 
        currActive = activeItem;
        resetAnchorLinks();
        let anchorLink = document?.querySelector(`[data-target="#${currActive.toString()}"]`);
        if (!anchorLink) {
          anchorLink = document?.querySelector(`[data-target="#intro"]`);
        }
          anchorLink.closest("li")?.classList.add('is-active');
          anchorIcon.style.top = `${anchorLink.closest("li").offsetTop - anchor.offsetTop}px`

          console.log("anchorLink.offsetTop - anchor.offsetTop", anchorLink.closest("li").offsetTop)
        
      }
      
    }

    anchorBlock.addEventListener('scroll', anchorBlockScroll);
    window.addEventListener('scroll', splitTextScroll);
  
    return () => {
      anchorBlock?.removeEventListener('scroll', anchorBlockScroll);
      window.removeEventListener('scroll', splitTextScroll);
    };
  }, [])

  return (
    <Layout page="work" route="/portfolio/conservation-international">
      <Seo 
        image={'/conservation-international/cover.jpg'}
        title="Conservation International by Alistair Bancroft"
        description={'We took great pride in developing a compelling website for Conservation International, dedicated to promoting their impactful "Hear Me While You Can" campaign — an initiative giving nature a voice.'}
      />
      <link href="https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap" rel="stylesheet" />
      <div className="conservation-international-namespace">
        <ProjectHero
          agency={'Inviqa'}
          body={['We took great pride in developing a compelling website for Conservation International, dedicated to promoting their impactful "Hear Me While You Can" campaign — an initiative giving nature a voice. Through our platform, visitors were invited to immerse themselves in a breathtaking auditory journey. Listening closely, they can explore the enchanting soundscapes of nature — be it the intricate melodies of birdsongs, the gentle hum of insects, or the soothing babble of trickling streams — that transcends borders and continents.', 'Our interactive website allows users to delve deeper into these distinctive regions, experiencing the sounds that need protection and understanding the urgency of safeguarding them. As users explore these vulnerable areas, they simultaneously contribute to fundraising efforts through streams of donations, empowering them to make a tangible impact on the preservation of these delicate ecosystems.']}
          engagement={'2021'}
          technologies={'Next.js, Netlify, Gsap, Three.js'}
          position={'Front End Lead / Inviqa'}
          project={{
            label: 'CONSERVATION INTERNATIONAL',
          }}
          title={'Project: Listen For Change'}
          subtitle={"Hear me while you can: An immersive journey into nature's soundscapes"}
        />

        <section data-background="ltOrange" className="aspect-square bg-black md:aspect-[1920/1080] flex overflow-hidden relative w-full inview globe-wrapper">
          <GlobeView 
            regions={[
              {
                id: 101,
                name: "Amazonia",
                hotspot: "Amazonia",
                subtitle: "South America",
                image: "/conservation-international__amazonia.jpg",
              },
              {
                id: 102,
                name: "New Guinea",
                hotspot: "NewGuinea",
                region: "Pacific",
                image: "/conservation-international__new-guinea.jpg",
              },
              {
                id: 103,
                name: "Guiana Coast",
                hotspot: "GuianaCoast",
                region: "South America",
                image: "/conservation-international__guiana-coast.jpg",
              },
              {
                id: 104,
                name: "Congo Basin",
                hotspot: "CongoBasin",
                region: "Africa",
                image: "/conservation-international__thumbnail.jpg",
              },
              {
                id: 105,
                name: "Southeast Australia",
                hotspot: "SoutheastAustralia",
                image: "/conservation-international__southeast-australia.jpg",
              },
              {
                id: 106,
                name: "Pacific Northwest",
                hotspot: "PacificNorthwest",
                region: "North America",
                image: "/conservation-international__pacific-northwest.jpg",
              },
              {
                id: 107,
                name: "Western Patagonia",
                hotspot: "WesternPatagonia",
                region: "South America",
                image: "/conservation-international__western-patagonia.jpg",
              }
            ]}
            hotspots={true}
          />
          <div className="absolute container h-10 hidden md:flex inset-x-0 items-center mx-auto py-8 px-5 md:px-6 text-xs text-white top-0 z-10">
            <button
              className="bg-primary-pink cursor-pointer mr-4 py-2 px-4 text-xs text-white uppercase"
              onClick={() => {toggleGlobe(true, globeHideButton === 'Toggle Show' ? "welcome" : "animate-out"); 
              handleGlobeHideButton(globeHideButton === 'Toggle Show' ? 'Toggle Hide' : 'Toggle Show');}}>Toggle Globe</button>
            
            <ul className="flex items-center text-white">
              <li className="mr-2">Toggle hotspots: </li>
              <li className="mr-2">
                <button
                  className="border border-white cursor-pointer h-5 relative rounded-full w-5 "
                  onClick={() => {
                    toggleHotspots('hotspot-toggle--on');
                    toggleGlobe(true, "welcome");
                  }}
                >
                  <span className={`absolute bg-white duration-300 h-3 inset-x-0 inset-y-0 m-auto pointer-events-none rounded-full transition w-3 opacity-100 hotspot-toggle hotspot-toggle--on`}></span>
                </button>
              </li>
              <li>
                <button
                  className="border border-white cursor-pointer h-5 relative rounded-full w-5"
                  onClick={(e) => {
                    toggleHotspots('hotspot-toggle--off');
                    toggleGlobe(false, "welcome");
                  }}
                >
                  <span className={`absolute bg-white duration-300 h-3 inset-x-0 inset-y-0 m-auto pointer-events-none rounded-full transition w-3 opacity-0 hotspot-toggle hotspot-toggle--off`}></span>
                </button>
              </li>
            </ul>
          </div>
        </section>
        
        <ProjectTitle
          text={{
            title: 'orange',
          }}
          content={[{
            title: '3D Globe Navigation',
            body: 
            ["A highlight of the website is its utilization of a captivating 3D globe, which skillfully transports users across the globe, vividly displaying the scale of the environmental challenges we collectively face."]
          }]}
        />
        
        <section data-background="ltOrange" className="bg-black md:flex items-center min-h-half-screen overflow-hidden px-5 py-24 md:py-40 relative w-full inview">
          <div className="mb-8 md:mb-0 w-full">
            
            <div className="font-thin font-lato leading-none mb-4 text-sm text-white uppercase">Original</div>
            <div className="font-bold font-lato leading-none text-[8vw] md:text-[5vw] text-white uppercase">
              Guianan Coast
            </div>
            <div className="font-thin font-lato italic text-[2.4vw] md:text-[1.5vw] text-conservationInternational-primary uppercase">
              South America
            </div>
          </div>
          <div className="w-full">
            <div className="font-thin font-lato leading-none mb-4 text-sm text-white uppercase">Elastic</div>
            <div className="font-bold font-lato leading-none text-[8vw] md:text-[5vw] text-white uppercase split-text">
              Guianan Coast
            </div>
            <div className="font-thin font-lato italic text-[2.4vw] md:text-[1.5vw] text-conservationInternational-primary uppercase">
              South America
            </div>
          </div>
        </section>

        <ProjectTitle 
          text={{
            title: 'orange',
          }}
          content={[{
            title: 'Elastic Text and Inertia',
            body: ["Subtle effects such as elastic text and scroll inertia adds an element of dynamism into the scrolling process, creating a delightful bounce-back effect as users navigate through our content.  The elasticity of the text not only adds a playful touch but also enhances readability, ensuring that users stay engaged and immersed as they explore."]
          }]}
        />
        
        <section data-background="ltOrange" className="bg-black min-h-half-screen overflow-hidden relative w-full inview">
          <div className="flex items-start mx-auto px-5 py-24 md:py-40 relative anchor-menu">
            <span aria-hidden="true" className="icon--bullet" style={{ top: "0px" }}></span>
            <ul className="anchor-list">
              <li className="is-active">
                <button data-target="#intro">Intro</button>
              </li>
              <li>
                <button data-target="#future">Future</button>
              </li>
              <li>
                <button data-target="#act">Act</button>
              </li>
              <li>
                <button data-target="#regions">Regions</button>
              </li>
              <li>
                <button data-target="#research">Research</button>
              </li>
            </ul>
            <div className="absolute h-full left-0 overflow-scroll ml-40 py-24  text-white top-0 uppercase anchor-block w-full">
              <div id="intro" className="mb-[300px] py-10 anchor-block__title">
                <div className="font-bold font-lato mb-2 text-4xl">Intro</div>
                <div className="font-thin font-lato italic text-xs text-conservationInternational-primary uppercase">Scroll Down</div>
              </div>
              <div id="future" className="mb-[300px] py-10 anchor-block__title">
                <div className="font-bold font-lato mb-2 text-4xl">Future</div>
                <div className="font-thin font-lato italic text-xs text-conservationInternational-primary uppercase">Scroll Down</div>
              </div>
              <div id="act" className="mb-[300px] py-10 anchor-block__title">
                <div className="font-bold font-lato mb-2 text-4xl">Act</div>
                <div className="font-thin font-lato italic text-xs text-conservationInternational-primary uppercase">Scroll Down</div>
              </div>
              <div id="regions" className="mb-[300px] py-10 anchor-block__title">
                <div className="font-bold font-lato mb-2 text-4xl">Regions</div>
                <div className="font-thin font-lato italic text-xs text-conservationInternational-primary uppercase">Scroll Down</div>
              </div>
              <div id="research" className="mb-[400px] py-10 anchor-block__title">
                <div className="font-bold font-lato mb-2 text-4xl">Research</div>
                <div className="font-thin font-lato italic text-xs text-conservationInternational-primary uppercase">Scroll Down</div>
              </div>
            </div>
          </div>
        </section>
        <ProjectTitle 
          text={{
            title: 'orange',
          }}
          content={[{
            title: 'Fixed Anchor Navigation',
            body: [
              "Fixed anchor links allows users to effortlessly traverse the page as they scroll, with the anchor link updating dynamically to indicate their current section - providing quick access to essential sections whilst promoting easy exploration of the website's diverse offerings",
              "The result provides a seamless and intuitive journey through the page content, empowering users to focus on what matters most."
            ]
          }]}
        />
        


        {/* A highlight of the website is its utilization of a captivating 3D globe, which skillfully transports users across the globe, vividly displaying the scale of the environmental challenges we collectively face. */}
        {/* In collaboration with Conservation International, we strived to create a meaningful digital platform that not only raises awareness of the urgent need for environmental conservation but also sparks empathy and action among visitors. By lending nature a voice through this harmonious digital experience, we hope to inspire positive change and support vital conservation efforts worldwide. */}
      </div>
      <div data-background="ltOrange" className="h-half-screen"></div>
    </Layout>
  )
}

export default ArsenalPage
