import * as THREE from "three";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer";

export function createRenderer(
  canvas: HTMLCanvasElement,
  sizes: { width: number; height: number }
) {
  const renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true,
  });
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.shadowMap.enabled = true;
  // @ts-ignore
  renderer.shadowMapSoft = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.setSize(sizes.width, sizes.height);
  renderer.setClearColor(0xbbdbc1, 0);
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.5;

  return renderer;
}

export function createCSSRenderer(
  width: number,
  height: number,
  container: HTMLDivElement
) {
  const cssRenderer = new CSS2DRenderer();
  cssRenderer.setSize(width, height);
  cssRenderer.domElement.style.position = "absolute";
  cssRenderer.domElement.style.top = "0px";
  container.appendChild(cssRenderer.domElement);

  return cssRenderer;
}
