import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { createRenderer, createCSSRenderer } from "./renderer";
import { createCamera } from "./camera";
import { createOrbitControls } from "./controls";
import GlobeController, { GlobeSettings } from "./controller";
import { setupLights } from "./lighting";

export type GlobeEventHandler = (evt: any, intersections: string[]) => void;

export interface GlobeEvents {
  mousemove?: GlobeEventHandler;
  pointerdown?: GlobeEventHandler;
}

function addClass(elem, className) {
  let classes = (className || "").split(" ");

  for (var i = classes.length - 1; i >= 0; i--) {
    if (elem.classList) elem.classList.add(classes[i]);
    else if (!hasClass(elem, classes[i])) elem.className += " " + classes[i];
  }

  return elem;
}


const windowHeight = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];

  return w.innerHeight || e.clientHeight || g.clientHeight;
};

const windowWidth = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];

  return w.innerWidth || e.clientWidth || g.clientWidth;
};

const modelSrc = "/earth.glb";

const init = (
  container: HTMLDivElement,
  callback: (controller: GlobeController) => void,
  settings?: GlobeSettings
) => {
  const sizes = {
    width: container.getBoundingClientRect()?.width || windowWidth(),
    height: container?.getBoundingClientRect()?.height || windowHeight(),
  };
  const canvas = container.querySelector("canvas.webgl") as HTMLCanvasElement;
  const scene = new THREE.Scene();

  if (!canvas) {
    return;
  }

  const renderer = createRenderer(canvas, sizes);
  const cssRenderer = createCSSRenderer(sizes.width, sizes.height, container);
  const camera = createCamera(sizes);
  const controls = createOrbitControls(camera, cssRenderer.domElement as any);
  const lights = setupLights(scene, camera);

  scene.add(camera);
  camera.position.set(0, 1, 10);

  // Load model
  setTimeout(() => {
    loadModel(modelSrc, (model) => {
      scene.add(model);

      const components = {
        container,
        scene,
        camera,
        renderer,
        controls,
        model,
        cssRenderer,
        // effectComposer,
      };

      const controller = new GlobeController(components, settings);
      callback(controller);
    });
  }, 800);
};

/**
 * Model
 */

const loadModel = (src: string, onLoad: (model: THREE.Object3D) => void) => {
  const gltfLoader = new GLTFLoader();

  gltfLoader.load(src, (gltf) => {
    const model = gltf.scene;
    const earthModel = model.getObjectByName("EarthModel") as THREE.Object3D;
    onLoad(earthModel);
  });
};

/**
 * Raycasting
 */
function mapIntersectionName(intersection: THREE.Intersection) {
  return intersection?.object?.name;
}

function intersectionEventHandler(
  raycaster: THREE.Raycaster,
  mouse: THREE.Vector2,
  controller: GlobeController,
  eventHandler?: GlobeEventHandler
) {
  return (evt: MouseEvent) => {
    if (!controller || !controller.controls?.enabled || !eventHandler) {
      return;
    }

    evt.preventDefault();
    const w = window.innerWidth;
    const h = window.innerHeight;
    mouse.x = (evt.clientX / w) * 2 - 1;
    mouse.y = -(evt.clientY / h) * 2 + 1;
    raycaster.setFromCamera(mouse, controller.camera!);
    const intersects = raycaster.intersectObjects(
      controller.scene!.children,
      true
    );

    const objectNames = intersects.map(mapIntersectionName);

    eventHandler(evt, objectNames);
  };
}

const eventHandlers = (controller: GlobeController, events: GlobeEvents) => {
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  return {
    handleMouseMove: intersectionEventHandler(
      raycaster,
      mouse,
      controller,
      events.mousemove
    ),
    handleClick: intersectionEventHandler(
      raycaster,
      mouse,
      controller,
      events.pointerdown
    ),
  };
};

// Resize the stage
const handleResize =
  (container: HTMLDivElement, controller: GlobeController) => () => {
    const w = container?.getBoundingClientRect()?.width || window.innerWidth;
    const h = container?.getBoundingClientRect()?.height || window.innerHeight;

    if (controller.camera && controller.renderer) {
      controller.camera.aspect = w / h;
      controller.camera.updateProjectionMatrix();
      controller.renderer.setSize(w, h);
      controller.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      controller?.cssRenderer?.setSize(w, h);

      controller.transform(controller.getCurrentPosition());
    }
  };

/**
 * Initialize experience
 */
export function load(
  container: HTMLDivElement,
  callback: (controller: GlobeController) => void,
  settings?: GlobeSettings,
  events?: GlobeEvents
) {
  const teardownFns: Array<() => void> = [];
  let hasEnded = false;

  teardownFns.push(() => {
    hasEnded = true;
  });

  setTimeout(() => {
    init(
      container,
      (controller) => {
        if (hasEnded) {
          return;
        }

        addClass(container, "animate-in");

        const resizeHandler = handleResize(container, controller);
        window.addEventListener("resize", resizeHandler);

        if (events) {
          const domElement =
            controller.cssRenderer?.domElement || controller.container;
          const eventHandlerFns = eventHandlers(controller, events);
          const clickHandler = eventHandlerFns.handleClick;
          const mouseMoveHandler = eventHandlerFns.handleMouseMove;

          domElement?.addEventListener("pointerdown", clickHandler);
          domElement?.addEventListener("mousemove", mouseMoveHandler);

          teardownFns.push(() => {
            domElement?.removeEventListener("pointerdown", clickHandler);
            domElement?.removeEventListener("mousemove", mouseMoveHandler);
          });
        }

        resizeHandler();

        callback(controller);

        teardownFns.push(() => {
          window.removeEventListener("resize", resizeHandler);

          if (controller) {
            controller.teardown();
          }
        });
      },
      settings
    );
  }, 100);

  return () => teardownFns.forEach((fn) => fn());
}
