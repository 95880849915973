import * as THREE from "three";
import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { animate, createTransformerFn } from "./animations/transformations";
import hotspotsManager from "./hotspots";

export interface Components {
  container: HTMLDivElement;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  model: THREE.Object3D;
  controls: OrbitControls;
  cssRenderer: CSS2DRenderer;
  effectComposer?: { render: () => void };
}

export interface GlobeSettings {
  initialPosition?: string;
  rotateEarth?: boolean;
  rotateClouds?: boolean;
  enableControls?: boolean;
  prePosition?: string;
  showRegions?: boolean;
  showHotspots?: boolean;
}

class GlobeController {
  public container: HTMLDivElement | null;
  public scene: THREE.Scene | null;
  public camera: THREE.PerspectiveCamera | null;
  public renderer: THREE.WebGLRenderer | null;
  public cssRenderer: CSS2DRenderer | null;
  public model: THREE.Object3D | null;
  public controls: OrbitControls | null;
  public effectComposer?: { render: () => void };
  public clouds?: THREE.Object3D | null;
  public hotspots?: THREE.Object3D[] | null;

  public enableEarthRotation: boolean;
  public enableCloudRotation: boolean;
  public currentPosition: string;
  public showHotspots: boolean;
  public showRegions: boolean;

  public transform: (
    state: string,
    options?: {
      duration?: number;
      animate?: boolean;
    }
  ) => void;

  constructor(
    {
      container,
      scene,
      camera,
      renderer,
      controls,
      model,
      effectComposer,
      cssRenderer,
    }: Components,
    {
      initialPosition = "intro",
      enableControls = false,
      rotateClouds = false,
      rotateEarth = false,
      prePosition = "initial",
      showHotspots = false,
      showRegions = false,
    }: GlobeSettings = {}
  ) {
    this.container = container;
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.controls = controls;
    this.model = model;
    this.effectComposer = effectComposer;
    this.cssRenderer = cssRenderer;

    this.enableEarthRotation = rotateEarth;
    this.enableCloudRotation = rotateClouds;
    this.showHotspots = showHotspots;
    this.showRegions = showRegions;
    this.currentPosition = "";

    this.controls.enabled = enableControls;

    this.setupModel();
    this.play();
    this.transform = this.setupTransformations(prePosition, initialPosition);

    hotspotsManager.add(this.model, this.model.children, this.showHotspots);
  }

  private loop = () => {
    if (!this.isSetup()) {
      return;
    }

    this.controls?.update();

    if (this.effectComposer) {
      this.effectComposer.render();
    } else {
      this.renderer?.render(this.scene!, this.camera!);
    }

    this.cssRenderer?.render(this.scene!, this.camera!);

    this.animate();

    if (this.hotspots) {
      hotspotsManager.update(this.model!, this.camera!, this.model.children!);
    }
  };

  private animate = () => {
    if (this.clouds && this.getEnableCloudRotation())
      this.clouds.rotation.y -= 0.00018;
    if (this.model && this.getEnableEarthRotation())
      this.model.rotation.y += 0.0009;
  };

  private setupModel = () => {
    if (!this.model) {
      return;
    }

    const objects: any = {
      cloudsObj: null,
      hotspots: [],
    };

    this.model.traverse((child) => {
      if (child.name.includes("Hotspot")) {
        child.visible = this.showRegions;
      }
      if (child.name.endsWith("Hotspot")) {
        const c: any = child;
        c.material.opacity = 0.2;
        objects.hotspots.push(child);
      }
      if (child.getObjectByName("Clouds")) {
        objects.cloudsObj = child;
      }
    });

    this.clouds = objects.cloudsObj;
    this.hotspots = objects.hotspots;
  };

  private setupTransformations(prePosition: string, initialPosition: string) {
    const transformModel = createTransformerFn(this.camera!, this.model!);

    transformModel(prePosition, 0, false); // set model in pre-animated position
    transformModel(initialPosition); // animate into starting position
    this.setCurrentPosition(initialPosition);

    return (
      state: string,
      {
        duration,
        animate = true,
      }: { duration?: number; animate?: boolean } = {}
    ) => {
      if (this.hasPosition(state)) {
        return;
      }

      this.setCurrentPosition(state);

      // disable controls while animating
      this.disableControlsForDuration(
        () => {
          transformModel(state, duration, animate);
        },
        duration ? duration * 1000 : 2000
      );
    };
  }

  private disableControlsForDuration(during: () => void, duration: number) {
    const controlsAreEnabled = this.getEnableControls();

    if (controlsAreEnabled) {
      this.enableControls(false);
    }

    during();

    if (controlsAreEnabled) {
      setTimeout(() => {
        this.enableControls(true);
      }, duration);
    }
  }

  public play = () => {
    this.renderer?.setAnimationLoop(this.loop);
  };

  public stop = () => {
    this.renderer?.setAnimationLoop(null);
  };

  public isSetup = () => {
    return (
      this.controls &&
      this.scene &&
      this.camera &&
      this.clouds &&
      this.model &&
      this.renderer
    );
  };

  public enableControls = (bool: boolean) => {
    if (this.controls) {
      this.controls.enabled = bool;
    }
  };

  public getEnableControls = () => {
    return this.controls?.enabled;
  };

  public getEnableEarthRotation = () => {
    return this.enableEarthRotation;
  };

  public setEnableEarthRotation = (bool: boolean) => {
    this.enableEarthRotation = bool;
  };

  public getEnableCloudRotation = () => {
    return this.enableCloudRotation;
  };

  public setEnableCloudRotation = (bool: boolean) => {
    this.enableCloudRotation = bool;
  };

  public getCurrentPosition = () => {
    return this.currentPosition;
  };

  public setCurrentPosition = (position: string) => {
    this.currentPosition = position;
  };

  public hasPosition = (position: string) => {
    return position === this.currentPosition;
  };

  public getShowHotspots = () => {
    return this.showHotspots;
  };

  public setShowHotspots = (bool: boolean) => {
    if (this.showHotspots !== bool) {
      this.showHotspots = bool;
      hotspotsManager.toggle(this.scene, this.showHotspots);
    }
  };

  public getShowRegions = () => {
    return this.showRegions;
  };

  public setShowRegions = (bool: boolean) => {
    if (this.showRegions !== bool) {
      this.showRegions = bool;

      this.model?.traverse((child) => {
        if (child.name.includes("Hotspot")) {
          child.visible = this.showRegions;
        }
      });
    }
  };

  public setHotspotActive = (hotspotName?: string, active?: boolean) => {
    this.hotspots?.forEach((hotspot: any) => {
      animate(hotspot.material, {
        duration: 2,
        opacity: active && hotspot.name === hotspotName ? 0.5 : 0.05,
      });
    });
  };

  public teardown = () => {
    this.stop();
    this.renderer?.dispose();

    if (this.cssRenderer) {
      this.container?.removeChild(this.cssRenderer.domElement);
    }

    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.controls = null;
    this.model = null;
    this.clouds = null;

    this.enableEarthRotation = false;
    this.enableCloudRotation = false;
    this.showRegions = false;
    this.showHotspots = false;
    this.currentPosition = "initial";
  };
}

export default GlobeController;
