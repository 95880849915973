import * as THREE from "three";
import gsap from "gsap";

const BREAKPOINT = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export interface Tweens {
  camera: gsap.TweenVars;
  position: gsap.TweenVars;
  scale: gsap.TweenVars;
  rotation: gsap.TweenVars;
}



const DEFAULT_DURATION = 2;

const BASE_TWEENS: Tweens = {
  camera: {
    duration: DEFAULT_DURATION,
    x: 0,
    y: 1,
    z: 10,
    ease: "power3.inOut",
  },
  position: {
    duration: DEFAULT_DURATION,
    x: 0,
    y: 0,
    z: 0,
    ease: "power3.inOut",
  },
  scale: {
    duration: DEFAULT_DURATION,
    x: 1,
    y: 1,
    z: 1,
    ease: "power3.inOut",
  },
  rotation: {
    duration: DEFAULT_DURATION,
    x: 0,
    y: 0,
    z: 0,
    ease: "power3.inOut",
  },
};

function getCurrent(
  camera: THREE.PerspectiveCamera,
  model: THREE.Object3D
): Partial<Tweens> {
  return {
    camera: {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    },
    position: {
      x: model.position.x,
      y: model.position.y,
      z: model.position.z,
    },
    scale: {
      x: model.scale.x,
      y: model.scale.y,
      z: model.scale.z,
    },
    rotation: {
      x: model.rotation.x,
      y: model.rotation.y,
      z: model.rotation.z,
    },
  };
}

function extendBaseTweens(
  tweens: Partial<Tweens>,
  duration = DEFAULT_DURATION
) {
  return {
    camera: {
      ...BASE_TWEENS.camera,
      ...tweens.camera,
      duration,
    },
    position: {
      ...BASE_TWEENS.position,
      ...tweens.position,
      duration,
    },
    scale: {
      ...BASE_TWEENS.scale,
      ...tweens.scale,
      duration,
    },
    rotation: {
      ...BASE_TWEENS.rotation,
      ...tweens.rotation,
      duration,
    },
  };
}

export const set = gsap.set;
export const animate = gsap.to;

export function animateAll(
  camera: THREE.PerspectiveCamera,
  model: THREE.Object3D,
  tweens: Partial<Tweens>,
  duration?: number
) {
  const mergedTweens = extendBaseTweens(tweens, duration);
  animate(camera.position, mergedTweens.camera);
  animate(model.position, mergedTweens.position);
  animate(model.scale, mergedTweens.scale);
  animate(model.rotation, mergedTweens.rotation);
}

export const setAll = (model: THREE.Object3D, tweens: Partial<Tweens>) => {
  const mergedTweens = extendBaseTweens(tweens);
  set(model.position, mergedTweens.position);
  set(model.scale, mergedTweens.scale);
  set(model.rotation, mergedTweens.rotation);
};

// TODO: refactor once all hotspots are added

export const transform = (
  camera: THREE.PerspectiveCamera,
  model: THREE.Object3D,
  state: string,
  duration = DEFAULT_DURATION,
  animate = true
) => {
  const aniFn = (tween: Partial<Tweens>) =>
    animateAll(camera, model, tween, duration);

  const setFn = (tween: Partial<Tweens>) => setAll(model, tween);

  const fn = animate ? aniFn : setFn;

  const initialTween = {
    position: {
      y: 0,
    },
    scale: {
      x: 1,
      y: 1,
      z: 1,
    },
  };

  const introTween = {
    position: {
      y: -4,
    },
    scale: {
      x: 2,
      y: 2,
      z: 2,
    },
  };

  const landingTween = {
    position: {
      x: 0,
      y: 0,
    },
    scale: {
      x: 1.2,
      y: 1.2,
      z: 1.2,
    },
    rotation: {
      y: -1.2,
    },
  };

  const windowWidth = () => {
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName("body")[0];
  
    return w.innerWidth || e.clientWidth || g.clientWidth;
  };

  switch (state) {
    case "welcome":
      if (windowWidth() < BREAKPOINT.lg) {
        return fn({
          position: {
            y: 0,
            x: 0,
          },
          scale: {
            x: 1,
            y: 1,
            z: 1,
          },
          rotation: {
            x: 0,
            y: 0.2,
          },
        });
      } else {
        return fn({
          position: {
            y: 0,
            x: 0,
          },
          scale: {
            x: 1.2,
            y: 1.2,
            z: 1.2,
          },
          rotation: {
            x: 0,
            y: 0,
          },
        });
      }
    case "animate-out":
      return fn({
        position: {
          y: -5.5,
          x: 0,
        },
        scale: {
          x: 1.2,
          y: 1.2,
          z: 1.2,
        },
        rotation: {
          y: 1,
        },
      });
    case "offset-left":
      return fn({
        position: {
          y: 0,
          x: -3,
        },
        scale: {
          x: 1,
          y: 1,
          z: 1,
        },
        rotation: {
          x: 0,
          y: -0.4,
        },
      });

    case "initial":
      return fn(initialTween);
    case "intro":
      return fn(introTween);
    case "landing":
      return fn(landingTween);
    case "prelanding":
      return fn({
        ...landingTween,
        scale: {
          x: 1,
          y: 1,
          z: 1,
        },
      });
    case "postlanding":
      return fn({
        ...getCurrent(camera, model),
        position: {
          x: 0,
          y: 0,
        },
        scale: {
          x: 3,
          y: 3,
          z: 3,
        },
      });
    case "data":
      return fn({
        ...landingTween,
        position: {
          y: 0,
        },
        rotation: {
          y: 3.14,
        },
      });
    case "right":
      return fn({
        position: {
          x: 3,
        },
        rotation: {
          y: -1.05,
        },
      });
    case "hotspot-1":
      return fn({
        ...landingTween,
        rotation: {
          x: -0.2,
          y: -0.4,
        },
      });
    case "hotspot-2":
      return fn({
        ...landingTween,
        rotation: {
          x: -0.2,
          y: -2,
        },
      });
    default:
      return;
  }
};

export const createTransformerFn = (
  camera: THREE.PerspectiveCamera,
  model: THREE.Object3D
) => {
  return (state: string, duration?: number, animate = true) =>
    transform(camera, model, state, duration, animate);
};
