import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

export function setupLights(
  scene: THREE.Scene,
  camera: THREE.PerspectiveCamera
) {
  const ambientLight = new THREE.AmbientLight(0x333333);
  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);

  scene.add(ambientLight);

  directionalLight.position.set(15, 3, 5);
  camera.add(directionalLight);

  // const lightHelper = new THREE.DirectionalLightHelper(light, 2, "red")
  // scene.add(lightHelper)

  return {
    ambientLight,
    directionalLight,
  };
}

export function setupEnvironmentalLighting(
  scene: THREE.Scene,
  renderer: THREE.WebGLRenderer,
  callback: () => void
) {
  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  new RGBELoader()
    .setDataType(THREE.UnsignedByteType)
    .load("hdr_500.hdr", function (texture) {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;
      scene.environment = envMap;
      texture.dispose();
      pmremGenerator.dispose();
      texture.encoding = THREE.UVMapping;

      callback();
    });
}
