import React from "react";
import { renderToString } from "react-dom/server";
import * as THREE from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";

import { elementExists } from "../../../helpers";
import HoverIntent from "../../HoverIntent";
import { setupHoverEvents } from "../../HoverIntent/HoverIntent";

export type HotspotComponentProps = {
  label: string;
  region?: string;
  href?: string;
  image?: string;
};

export function createHotspotFromObject({
  position,
  props,
}: {
  position: THREE.Vector3;
  props: HotspotComponentProps;
}) {
  const div = document.createElement("div");
  const markerHtml = renderToString(
    <HoverIntent
      region={props.region}
      href={props.href}
      image={props.image}
      {...props}
    />
  );

  const elementExists = (obj) => {
    try {
      // Using W3 DOM2 (works for FF, Opera and Chrome)
      return obj instanceof HTMLElement;
    } catch (e) {
      // Browsers not supporting W3 DOM2 don't have HTMLElement and
      // an exception is thrown and we end up here. Testing some
      // properties that all elements have (works on IE7)
      return (
        typeof obj === "object" &&
        obj.nodeType === 1 &&
        typeof obj.style === "object" &&
        typeof obj.ownerDocument === "object"
      );
    }
  };
  
  div.innerHTML = markerHtml;

  const hotspot = new CSS2DObject(div);
  hotspot.position.set(position.x, position.y, position.z);

  const hoverIntent = hotspot.element?.querySelector(".hover-intent");

  if (elementExists(hoverIntent)) {
    setupHoverEvents(hoverIntent);
  }

  return hotspot;
}
