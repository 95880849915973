import * as THREE from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import { createHotspotFromObject } from "./hotspot-renderer";

export function updateHotspotVisibility(
  earth: THREE.Object3D,
  camera: THREE.PerspectiveCamera,
  hotspots: THREE.Object3D[]
) {
  const sphere = new THREE.Sphere();
  new THREE.Box3().setFromObject(earth).getBoundingSphere(sphere);
  const cameraToEarth = sphere.center.clone().sub(camera.position);
  const L = Math.sqrt(
    Math.pow(cameraToEarth.length(), 2) - Math.pow(sphere.radius, 2)
  );

  hotspots.forEach((hotspot) => {
    const box = new THREE.Box3().setFromObject(hotspot!);
    const position = new THREE.Vector3();
    box.getCenter(position);
    const cameraToPin: any = position.clone().sub(camera.position);
    const isVisible = cameraToPin?.length() - 1 < L; // - 1 to increase hotspot visibility
    // const isVisible = cameraToPin?.length() < L
    if (isVisible !== hotspot.userData.previousVisibility) {
      hotspot.userData.previousVisibility = isVisible;

      const child = hotspot.userData.hotspot as CSS2DObject | null;

      if (child) {
        child.element.style.opacity = isVisible ? "1.0" : "0.0";
      }
    }
  });
}

export function addHotspots(
  earth: THREE.Object3D,
  hotspots: THREE.Object3D[] | null | undefined,
  visible: boolean
) {
  hotspots?.forEach((obj) => {
    if (
      obj.type !== "Mesh" ||
      obj.name === "Earth" ||
      obj.name === "Clouds" ||
      obj.type === "Object3D"
    ) {
      return;
    }
    const box = new THREE.Box3().setFromObject(obj!);
    const position = new THREE.Vector3();
    box.getCenter(position);

    let label = obj.name;
    let region = "";
    let href = "";
    let image = "";

    const hotspot = createHotspotFromObject({
      position,
      props: {
        href,
        label,
        region,
        image,
      },
    });

    hotspot.visible = visible;

    earth.add(hotspot);
    obj.userData.hotspot = hotspot;
  });
}

export function toggleShowHotspots(
  scene: THREE.Scene | null | undefined,
  visible: boolean
) {
  scene?.traverse((obj) => {
    if (obj instanceof CSS2DObject) {
      obj.visible = visible;
    }
  });
}

const setupHotspots = {
  toggle: toggleShowHotspots,
  add: addHotspots,
  update: updateHotspotVisibility,
  create: createHotspotFromObject,
};

export default setupHotspots;
